import { Box, Center, HStack, Image } from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import styles from "./ResearchSelection.module.css";
import qual from "../../assets/images/qual.png";
import quant from "../../assets/images/quant.png";
import check from "../../assets/images/checkmark.png";
import { connect, ConnectedProps } from "react-redux";
import { updateProject } from "../../reducers/projects";

interface ResearchSelectionProps extends PropsFromRedux {}

const ResearchSelection: FC<ResearchSelectionProps> = (props: any) => {
  const [projectType, setProjectType] = useState(2);
  const affectLabLink =
    "https://benchmark.affectlab.io/applicant/token/fda46dcfbc5e4fd1a67cef9eea88c451";
  const decodeLink =
    "https://product.getdecode.io/live/us/01GAN7R0WN8TSTMR12S2ND3GRR";

  // useEffect(() => {
  //   const link = props.project.type == 1 ? affectLabLink : decodeLink;
  //   props.updateProject({ link: link });
  // }, []);

  // useEffect(() => {
  //   props.updateProject({
  //     link: props.project.type == 1 ? affectLabLink : decodeLink,
  //   });
  // }, [props.project.type]);

  return (
    <div className={styles.ResearchSelection}>
      <Box className={styles["form-container"]}>
        <Center>
          <Box className={styles["title"]}>
            What Type of Research Project do you need?
          </Box>
        </Center>
        <Center>
          <Box>
            <HStack>
              <Box
                className={
                  styles["select-box"] +
                  " " +
                  (projectType === 1 ? styles["selected"] : "")
                }
                onClick={() => {
                  setProjectType(1);
                  props.updateProject({ type: 1 });
                }}
              >
                {projectType === 1 && (
                  <Image className={styles["check"]} src={check} />
                )}
                <Image className={styles["select-img"]} src={qual} />
                <Box className={styles["select-title"]}>Survey</Box>
              </Box>
              <Box
                className={
                  styles["select-box"] +
                  " " +
                  (projectType == 2 ? styles["selected"] : "")
                }
                onClick={() => {
                  setProjectType(2);
                  props.updateProject({ type: 2 });
                }}
              >
                {projectType == 2 && (
                  <Image className={styles["check"]} src={check} />
                )}
                <Image className={styles["select-img"]} src={quant} />
                <Box className={styles["select-title"]}>Immersions</Box>
              </Box>
            </HStack>
          </Box>
        </Center>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    project: state.project.project,
  };
};

const mapDispatchToProp = (dispatch: any) => {
  return {
    updateProject: (payload: any) => {
      dispatch(updateProject(payload));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ResearchSelection);

import React, { useEffect } from "react";

const PageTitle = (props: any) => {
  useEffect(() => {
    window.document.title = props.message.message;
  }, []);
  return null;
};

const GetTitle = (props: any) => (
  <div>
    <PageTitle message={props} />
  </div>
);

export default GetTitle;

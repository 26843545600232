import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  shadows: {
    outline: "none",
  },
  colors: {
    text: "#2E2E2E",
    brand: {
      100: "#00205B",
      500: "#00205B",
    },
    audio: {
      500: "#9ce9fd",
    },
    video: {
      500: "#a6f2cd",
    },
    drawer: {
      100: "red",
    },

    switch: {
      500: "#64DAA1",
    },
    french_pass: {
      500: "#BBE7FE",
      200: "#1BC9FD",
    },
    thistle: {
      500: "#D3B5E5",
      200: "#8C7C8D",
    },
    pastel_pink: {
      500: "#FFD4DB",
      200: "#FF878D",
    },
    loafer: {
      500: "#EFF1DB",
      200: "#A7AB9B",
    },
    tequila: {
      500: "#FBE7C6",
      200: "#FF9717",
    },
    pale_turquoise: {
      500: "#B4F8C8",
      200: "#679C84",
    },
    water_leaf: {
      500: "#A0E7E5",
      200: "#639089",
    },
    soft_pink: {
      500: "#FFAEBC",
      200: "#FD194A",
    },
    tropical_blue: {
      500: "#CDDAFD",
      200: "#1785FB",
    },
    misty_rose: {
      500: "#FDE2E4",
      200: "#FF6554",
    },
    default: {
      500: "#A2DBFA",
      200: "#025DF4",
    },
  },
  fonts: {
    heading: "Manrope",
    body: "Manrope",
    button: "Manrope",
    fontSize: "sm",
  },
  fontSizes: {
    md: "14px",
    fs16: "16px",
    fs10: "10px",
  },
  components: {
    Checkbox: {
      variants: {
        decode_checkbox: {
          color: "#00205B",
          control: {
            height: "18px",
            width: "18px",
            _checked: {
              bg: "#64DAA1",
              borderColor: "#64DAA1",
            },
          },
        },
        decode_card_checkbox: {
          color: "#00205B",
          control: {
            height: "18px",
            width: "18px",
            border: "0.5px solid",
            borderRadius: "4px",
            bg: "#ffffff",
            _checked: {
              bg: "#64DAA1",
              borderColor: "#64DAA1",
              _hover: {
                bg: "#64DAA1",
                borderColor: "#64DAA1",
              },
            },
          },
        },
      },
    },
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: "bold",
        _focus: {
          boxShadow: "none",
        },
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xl: {
          h: "56px",
          fontSize: "lg",
          px: "32px",
        },
      },
      // 3. We can add a new visual variant
      variants: {
        "with-shadow": {
          bg: "transparent linear-gradient(90deg, #64DAA1 0%, #00B4E4 100%) 0% 0% no-repeat padding-box",
          boxShadow: "0 3px 10px #0000001a",
          borderRadius: "4px",
        },
        "without-gradient": {
          bg: "#FFFFFF",
          color: "#00205B",
          borderRadius: "4px",
        },
        "action-no-bg": {
          bg: "#FFFFFF",
          color: "#00B4E4",
          borderRadius: "4px",
          border: "1px solid #00B4E4",
          padding: "0 25px",
          height: "30px",
          minWidth: "90px",
        },
        "action-no-bg-noMinWidth": {
          bg: "#FFFFFF",
          color: "#00B4E4",
          borderRadius: "4px",
          border: "1px solid #00B4E4",
          padding: "0 25px",
          height: "30px",
        },
        "action-btn-primary": {
          bg: "#00b4e4",
          color: "#FFFFFF",
          borderRadius: "4px",
          border: "1px solid #00B4E4",
          padding: "0 25px",
          height: "40px",
          _hover: {
            bg: "#00b4e4 !important",
            color: "#FFFFFF",
          },
          _disabled: {
            bg: "#00b4e4",
            color: "#FFFFFF",
          },
        },
        "action-btn": {
          bg: "transparent linear-gradient(90deg, #64DAA1 0%, #00B4E4 100%) 0% 0% no-repeat padding-box",
          borderRadius: "4px",
          padding: "0 25px",
          height: "30px",
          color: "#FFFFFF",
          minWidth: "90px",
          _hover: {
            bg: "transparent linear-gradient(90deg, #64DAA1 0%, #00B4E4 100%) 0% 0% no-repeat padding-box",
            boxShadow: "0 3px 10px #0000001a",
            color: "#FFFFFF",
          },
          _active: {
            color: "#00205B",
          },
        },
        "action-btn-noMinWidth": {
          bg: "transparent linear-gradient(90deg, #64DAA1 0%, #00B4E4 100%) 0% 0% no-repeat padding-box",
          borderRadius: "4px",
          padding: "0 25px",
          height: "30px",
          color: "#FFFFFF",
          _hover: {
            bg: "transparent linear-gradient(90deg, #64DAA1 0%, #00B4E4 100%) 0% 0% no-repeat padding-box",
            boxShadow: "0 3px 10px #0000001a",
            color: "#FFFFFF",
          },
          _active: {
            color: "#00205B",
          },
        },
        "blue-background": {
          borderRadius: "0px",
          height: "30px",
          minWidth: "90px",
          margin: "0px",
          border: "1px solid #C9C9C9",
          padding: "0 25px",
        },
        search: {
          bg: "#FFFFFF",
          color: "#00205B",
          borderRadius: "4px",
          width: "100%",
          justifyContent: "left",
          _focus: {
            boxShadow: "none",
          },
        },
        clipBoard: {
          bg: "#FFFFFF",
          borderRadius: "4px",
          border: "1px solid #cccccc",
          height: "30px",
          minWidth: "90px",
          color: "inherit",
          fontWeight: "normal",
          padding: "18px 10px",
        },
        clipBoardInsights: {
          bg: "#FFFFFF",
          borderRadius: "4px",
          height: "30px",
          minWidth: "90px",
          color: "#00205B",
          fontWeight: "normal",
          padding: "18px 10px",
        },
        // 4. We can override existing variants
        solid: () => ({
          bg: "transparent linear-gradient(90deg, #64DAA1 0%, #00B4E4 100%) 0% 0% no-repeat padding-box",
          borderRadius: "0",
          padding: "0 25px",
          height: "40px",
          color: "#FFFFFF",
          _hover: {
            bg: "transparent linear-gradient(90deg, #64DAA1 0%, #00B4E4 100%) 0% 0% no-repeat padding-box",
            boxShadow: "0 3px 10px #0000001a",
            color: "#FFFFFF",
          },
          _active: {
            color: "#00205B",
          },
        }),
        "gray-action-btn": {
          padding: "0px 5px",
          height: "30px",
          color: "#BEBEBE",
          bg: "#FFFFFF",
          borderRadius: "4px",
          border: "1px solid #E2E2E2",
          fontSize: "12px",
          position: "relative",
        },
      },
    },
    Drawer: {
      variants: {
        sidenav: {
          dialog: {
            maxWidth: "200px",
          },
        },
        search: {
          dialog: {
            backgroundColor: "#00205b",
            color: "#ffffff",
            width: "94.5vw !important",
            left: "4vw !important",
          },
          dialogContainer: {
            zIndex: "1 !important",
            width: "96vw",
            left: "4vw",
          },
        },
        forms: {
          baseStyle: {
            fontWeight: "bold",
            color: "#fff",
          },
          header: {
            background: "#00205B",
            color: "#fff",
          },
          closeButton: {
            color: "#fff",
            paddingTop: "10px",
            _focus: {
              boxShadow: "none",
            },
          },
          dialogContainer: {
            zIndex: 20001,
          },
        },
        scrollableBackground: {
          parts: ["dialog, dialogContainer"],
          dialog: {
            pointerEvents: "auto",
            maxWidth: "350px !important",
            boxShadow: "-6px 6px 20px #00000026",
            border: "1px solid #E5E5E5",
            top: "105px !important",
          },
          dialogContainer: {
            pointerEvents: "none",
            // width: "350px",
          },
          header: {
            borderBottom: "1px solid #E5E5E5",
            fontSize: "16px",
            fontWeight: "800",
            padding: "0.8rem 0.5rem",
          },
          body: {
            padding: "0px",
            overflow: "hidden",
            height: "100%",
          },
        },
        live: {
          parts: ["dialog, dialogContainer"],
          dialog: {
            pointerEvents: "auto",
            maxWidth: "30vw !important",
          },
          dialogContainer: {
            pointerEvents: "none",
          },
        },
      },
    },
    Modal: {
      sizes: {
        "2xl": {
          dialog: {
            width: "1200px",
            maxWidth: "80vw",
            minHeight: "80vh",
          },
        },
      },
      variants: {
        search: {
          width: "1000px",
        },
      },
    },
    Tooltip: {
      defaultProps: {
        hasArrow: "true",
      },
      variants: {
        arrow: {
          bg: "#00205b",
          color: "#ffffff",
          fontSize: "12px",
        },
        whiteBg: {
          bg: "#FFFFFF",
          color: "#00205b",
          fontSize: "12px",
        },
        chatbox: {
          bg: "#00205b",
          color: "#fff",
          fontSize: "10px",
          fontWeight: "bold",
          padding: "8px",
          borderRadius: "4px",
        },
      },
    },
    Tabs: {
      variants: {
        enclosed: {
          baseStyle: {
            fontSize: "20px",
            color: "#00B4E4",
          },
        },
        mediaTabs: {
          tab: {
            color: "#868686",
            fontSize: "16px",
            letterSpacing: "0px",
            padding: "5px",
            margin: "0px 40px 0px 0px",
            borderBottom: "2px",
            borderColor: "#ffffff",
            _selected: {
              borderColor: "#00205B",
              color: "#00205B",
            },
          },
          tablist: {
            bg: "#ffffff",
            padding: "0px 20px",
            boxShadow: "2px 3px 8px -2px #0000001a",
          },
          tabpanel: {
            paddingTop: "0px",
          },
        },
      },
    },
    Link: {
      baseStyle: {
        fontWeight: "bold",
        color: "#00B4E4",
      },
    },
    Input: {
      baseStyle: {
        field: {
          _active: {},
        },
      },
      sizes: {},
      variants: {
        outline: {
          field: {
            backgroundColor: "#ffffff",
            _active: {
              borderColor: "#00205B",
            },
            _focus: {
              borderColor: "#00205b",
            },
          },
        },
      },
    },
    Textarea: {
      baseStyle: {
        field: {
          _active: {
            borderColor: "#00205B",
          },
          _focus: {
            border: "1px solid #00205b",
          },
        },
      },
      sizes: {},
      variants: {
        outline: {
          field: {
            backgroundColor: "#ffffff",
            _active: {
              borderColor: "#00205B",
            },
            _focus: {
              borderColor: "#00205b",
            },
          },
        },
      },
    },
    Badge: {
      variants: {
        rounded_info: {
          borderRadius: "50%",
          display: " flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#00B4E4",
          color: "#fff",
        },
      },
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        color: "#414042",
        fontSize: "sm",
        background: "#f4f7fc",
        overflow: "hidden",
      },
    },
  },
});

export default theme;

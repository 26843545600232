import { Box, Center, Flex, Image, Link, useToast } from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import styles from "./Login.module.css";
import logo from "../../assets/images/entropik-decode.svg";
import illustration from "../../assets/images/illustration1.png";
import { useForm } from "react-hook-form";
import { Link as ReachLink, useNavigate } from "react-router-dom";
import {
  FormErrorMessage,
  FormLabel,
  Input,
  FormControl,
  Button,
  InputGroup,
  InputRightElement,
  Grid,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import TextInput from "../../components/Form/TextInput";
import microsoft from "../../assets/images/logos/microsoft.png";
import transparent from "../../assets/images/transparent.png";
import { Static } from "../../utility/static";
import DottedLines from "../../components/DottedLines/index";
import GetTitle from "../../components/GetTitle";
import { connect, ConnectedProps } from "react-redux";
import { loginUser, setEmail, setRole } from "../../reducers/user";

import { db, auth } from "../../firebase";
import {
  collection,
  addDoc,
  Timestamp,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
// import LoginForm from "./LoginForm";

interface LoginProps extends PropsFromRedux {}

const Login: FC<LoginProps> = (props: any) => {
  localStorage.setItem("loggedIn", "false");
  const LogInForm = () => {
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm({ mode: "onSubmit" });
    const password = watch("password", "");
    const email = watch("email", "");
    const [showPassword, setShowPassword] = React.useState(false);
    const [showError, setShowError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleClickPassword = () => setShowPassword(!showPassword);
    const navigate = useNavigate();
    const toast = useToast();

    const fetchUser = async (email: any) => {
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data();
        console.log(doc.id, " => ", doc.data());
        props.setRole(data.role);
        localStorage.setItem("loggedIn", "true");
        localStorage.setItem("role", data.role);
      });
      props.login(true);
      navigate("/dashboard");
    };

    useEffect(() => {
      setShowError(false);
    }, [password, email]);

    const logInWithEmailAndPassword = async (email: any, password: any) => {
      try {
        const resData = await signInWithEmailAndPassword(auth, email, password);
        console.log(resData);
        fetchUser(email);
        props.setEmail(email);
      } catch (err: any) {
        setIsSubmitted(false);
        toast({
          title: err.message,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      }
    };

    const onSubmit = async (data: any) => {
      setIsSubmitted(true);
      setShowError(false);
      logInWithEmailAndPassword(data.email, data.password);
    };
    // console.log(errors);

    useEffect(() => {
      // console.log(errors);
      if (errors.email) {
        // setFocus("email");
      }
    }, [errors]);

    const redirect = (ip: string) => {
      const payload: any = {
        is_signin: true,
        identity_provider: ip,
      };
      console.log(payload);

      return null;
    };

    return (
      <Box className={styles["login--box"]}>
        <GetTitle message="Decode CX: Login" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            name="email"
            placeholder="Email"
            type="text"
            label="Enter your email address"
            labelClassName={styles["form--label"]}
            RightIconElement={
              <InputRightElement width="3.5rem">
                <Image
                  src={transparent}
                  alt="user"
                  className={styles["user--icon"]}
                />
              </InputRightElement>
            }
            error={errors["email"]}
            register={register}
            registerOptions={{
              required: { value: true, message: "Email is required" },
              pattern: {
                value: Static.emailRegexPattern,
                message: "Enter a valid email address",
              },
            }}
            className={styles["email--text"]}
          />
          <FormControl isInvalid={errors.password} mt="14px">
            <FormLabel htmlFor="password" className={styles["form--label"]}>
              Enter your password
            </FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                autoComplete="off"
                variant="outline"
                color="#00205B"
                fontSize="16px"
                fontWeight="medium"
                bg="#F7F7F7"
                className={styles["password--text"]}
                {...register("password", {
                  required: true,
                  maxLength: 50,
                  minLength: 8,
                })}
                _placeholder={{ fontSize: "16px" }}
              />
              <InputRightElement width="3.5rem">
                {showPassword ? (
                  <Image
                    src={transparent}
                    alt="view-on"
                    onClick={handleClickPassword}
                    className={styles["view-on--icon"]}
                  />
                ) : (
                  <Image
                    src={transparent}
                    alt="view-off"
                    onClick={handleClickPassword}
                    className={styles["view-off--icon"]}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage fontSize="xs">
              {errors.password?.type === "required" && "Password is required"}
              {errors.password?.type === "minLength" &&
                "Password must have mimimum 8 characters"}
              {errors.password?.type === "maxLength" &&
                "Password must have maximum 50 characters"}
            </FormErrorMessage>
          </FormControl>
          {showError && (
            <Box className={styles["login__error"]}>
              Please enter a valid email address or password
            </Box>
          )}
          <Box className={styles["forgot-password--box"]}>
            <Link
              className={styles["forgot-password--text"]}
              as={ReachLink}
              to="/forgot-password"
            >
              Forgot password?
            </Link>
          </Box>
          <Button
            mt={4}
            type="submit"
            isLoading={isSubmitted}
            spinnerPlacement="start"
            disabled={isSubmitted || showError}
            w="100%"
          >
            Login
          </Button>
        </form>
      </Box>
    );
  };

  return (
    <div className={styles.Login}>
      <Flex h="100vh">
        <Box w="68%" bg="#f3f5fd">
          <Image className={styles["slide-image"]} src={illustration} />
        </Box>
        <Box w="32%" p="20px">
          <Center>
            <Image src={logo} alt="decode" className={styles["decode-logo"]} />
          </Center>
          <Center>
            <Box pl="30px" textAlign="left">
              <p className={styles["title--bold"]}>Sign In to Decode</p>
            </Box>
          </Center>
          <LogInForm />
          <Box textAlign="center" mt="20px">
            Don't have an account yet?{" "}
            <Link
              color="#00B4E4"
              fontWeight="600"
              href={"http://dev.getdecode.io/signup"}
            >
              Create an account
            </Link>
          </Box>
        </Box>
      </Flex>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loggedIn: state.loggedIn,
  };
};
const mapDispatchToProp = (dispatch: any) => {
  return {
    login: (flag: any) => {
      dispatch(loginUser(flag));
    },
    setRole: (flag: any) => {
      dispatch(setRole(flag));
    },
    setEmail: (flag: any) => {
      dispatch(setEmail(flag));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Login);

import {
  Box,
  Button,
  HStack,
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  Divider,
  useToast,
} from "@chakra-ui/react";
import React, { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProjectForm from "../../components/ProjectForm/ProjectForm";
import ProjectFormPreview from "../../components/ProjectFormPreview/ProjectFormPreview";
import ResearchSelection from "../../components/ResearchSelection/ResearchSelection";
import SurveyForm from "../../components/SurveyForm/SurveyForm";
import styles from "./CreateProject.module.css";
import { db } from "../../firebase";
import projects from "../../utility/dummy/dummyProject.json";
import { connect, ConnectedProps } from "react-redux";

import {
  collection,
  addDoc,
  Timestamp,
  doc,
  getDoc,
  getDocs,
  query,
} from "firebase/firestore";
import GetTitle from "../../components/GetTitle";
import { updateProject } from "../../reducers/projects";
import LoggedIn from "../../components/LoggedIn/LoggedIn";

interface CreateProjectProps extends PropsFromRedux {}

const CreateProject: FC<CreateProjectProps> = (props: any) => {
  const tabList = [
    { id: 1, name: "Step 1" },
    { id: 2, name: "Step 2" },
    { id: 3, name: "Step 3" },
    { id: 4, name: "Step 4" },
  ];
  const [tabIndex, setTabIndex] = useState(0 as any);
  const tabRef: any = useRef(null);
  const navigate = useNavigate();
  const toast = useToast();
  const next = async () => {
    let tabInd: any = tabIndex;
    if (tabInd < 3) {
      tabInd = tabInd + 1;
    } else {
      try {
        await addDoc(collection(db, "projects"), props.project);
        navigate("/projects");
        toast({
          title: "Project created.",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
      } catch (err) {
        alert(err);
      }
    }
    setTabIndex(tabInd);
  };

  return (
    <div className={styles.CreateProject}>
      <GetTitle message="Decode CX: Create Project" />
      <LoggedIn />
      <Box>
        <Box className={styles["header"]}>
          <HStack>
            <Box className={styles["header-title"]}>Create Project</Box>
          </HStack>
        </Box>
        <Box className={styles["project-box"]}>
          <Tabs
            variant="enclosed"
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
            ref={tabRef}
            isFitted
          >
            <TabList>
              {tabList.map((tabs: any) => {
                return (
                  <>
                    <Tab>{tabs.name}</Tab>
                  </>
                );
              })}
            </TabList>
            <TabPanels>
              <TabPanel>
                <ResearchSelection />
              </TabPanel>
              <TabPanel>
                <ProjectForm />
              </TabPanel>
              <TabPanel>{tabIndex === 2 && <SurveyForm />}</TabPanel>
              <TabPanel>
                <ProjectFormPreview />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Divider />
          <Box textAlign="right">
            <Button mt="20px" variant="action-btn" onClick={() => next()}>
              {tabIndex == 3 ? "Submit Request" : "Next"}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    project: state.project.project,
  };
};
const mapDispatchToProp = (dispatch: any) => {
  return {
    updateProject: (payload: any) => {
      dispatch(updateProject(payload));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(CreateProject);

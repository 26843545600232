import { Box, Grid, GridItem, HStack, Image, Spacer } from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import styles from "./ProjectStatus.module.css";
import active from "../../assets/images/active.svg";
import closed from "../../assets/images/closed.svg";
import resp from "../../assets/images/respondents.svg";
import ProjectTable from "../../components/ProjectTable/ProjectTable";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import _ from "lodash";
import GetTitle from "../../components/GetTitle";
import LoggedIn from "../../components/LoggedIn/LoggedIn";

interface ProjectStatusProps {}

const ProjectStatus: FC<ProjectStatusProps> = () => {
  const [projects, setProjects] = useState([] as any);
  const [avg, setAvg] = useState(0);
  console.log(projects);
  useEffect(() => {
    fetchProjects();
  }, []);

  const projectEvent = () => {
    fetchProjects();
  };

  const fetchProjects = async () => {
    const arr: any = [];
    const querySnapshot = await getDocs(collection(db, "projects"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const data = doc.data();
      data["fid"] = doc.id;
      arr.push(data);
    });
    arr.sort((a: any, b: any) => {
      const [dayA, monthA, yearA] = a.start_date.split('/');
      const [dayB, monthB, yearB] = b.start_date.split('/');
      const dateA: any = new Date(`${yearA}-${monthA}-${dayA}`);
      const dateB:any = new Date(`${yearB}-${monthB}-${dayB}`);
      return dateB - dateA;
    });
    setProjects(arr);
  console.log(arr);

    let sum = 0;
    for (let index = 0; index < arr.length; index++) {
      sum += arr[index].no_resp.length && JSON.parse(arr[index].no_resp) || 0;
    }
    setAvg(sum / arr.length);
  };

  return (
    <div className={styles.ProjectStatus}>
      <GetTitle message="Decode CX: Project status" />
      <LoggedIn />
      <Box className={styles["container"]}>
        <Box className={styles["header"]}>Projects</Box>
        <Box>
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            <GridItem>
              <Box className={styles["grid-box"]}>
                <HStack alignItems="flex-start">
                  <Box>
                    <Box className={styles["title"]}>Active Projects</Box>
                    <Box className={styles["content"]}>{projects.length}</Box>
                  </Box>
                  <Spacer />
                  <Box className={styles["image-box"]}>
                    <Image className={styles["image"]} src={closed} />
                  </Box>
                </HStack>
              </Box>
            </GridItem>
            <GridItem>
              <Box className={styles["grid-box"]}>
                <HStack alignItems="flex-start">
                  <Box>
                    <Box className={styles["title"]}>Closed Projects</Box>
                    <Box className={styles["content"]}>0</Box>
                  </Box>
                  <Spacer />
                  <Box className={styles["image-box"]}>
                    <Image className={styles["image"]} src={active} />
                  </Box>
                </HStack>
              </Box>
            </GridItem>
            <GridItem>
              <Box className={styles["grid-box"]}>
                <HStack alignItems="flex-start">
                  <Box>
                    <Box className={styles["title"]}>Avg Participants</Box>
                    <Box className={styles["content"]}>
                      {avg && Math.round(avg)}
                    </Box>
                  </Box>
                  <Spacer />
                  <Box className={styles["image-box"]}>
                    <Image className={styles["image"]} src={resp} />
                  </Box>
                </HStack>
              </Box>
            </GridItem>
          </Grid>
        </Box>
        <br></br>
        <Box className={styles["header"]}>Request Projects</Box>
        <ProjectTable projects={projects} projectEvent={projectEvent} />
      </Box>
    </div>
  );
};

export default ProjectStatus;

import teams from "../../src/assets/images/logos/teams.png";
import zoom from "../../src/assets/images/logos/zoom.png";
import slack from "../../src/assets/images/logos/slack.png";
import gmeet from "../../src/assets/images/logos/gmeet.png";
import webex from "../../src/assets/images/logos/webex.png";
import gtm from "../../src/assets/images/logos/gtm.png";
import decode from "../../src/assets/images/decode/decode-logo.png";

export const Static = {
  emailRegexPattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  passwordRegexPattern:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#%^!%*?&_])[A-Za-z\d@$!#^%*?_&]{8,50}$/,
  specialCharacters: /^[a-z0-9 ]+$/i,
  nonAuthenticatedRoutes: [
    "/login",
    "/forgot-password",
    "/confirm-forgot-password",
    "/signup",
    "/verification-code",
    "/collect-user-info",
    "/collect-tenant-info",
    "/app-connector",
    "/invite-user",
    "/invitee-user-info",
    "/invitee-verification-code",
    "/live",
    "/insights/present",
    "/callback",
  ],
  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  months: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  connectors: [
    {
      id: 1,
      name: "Teams",
      imageSrc: teams,
    },
    {
      id: 2,
      name: "Zoom",
      imageSrc: zoom,
    },
    {
      id: 3,
      name: "Slack",
      imageSrc: slack,
    },
    {
      id: 4,
      name: "GMeet",
      imageSrc: gmeet,
    },
    {
      id: 5,
      name: "Webex",
      imageSrc: webex,
    },
    {
      id: 6,
      name: "GTM",
      imageSrc: gtm,
    },
  ],
  HIGHLIGHTS_COLORS: [
    "#CDDDFD",
    "#CDF3E4",
    "#CED4DE",
    "#FCEBB9",
    "#D3CEFD",
    "#D3EEF9",
    "#DECFEA",
    "#FFE0C7",
    "#BBDEDE",
    "#FFE0ED",
    "#DFE1E6",
    "#A0F7FF",
    "#E6FCFF",
    "#E3FCEF",
    "#DEEBFF",
    "#FFFAE6",
    "#EAE6FF",
    "#FFEBE6",
    "#FDEDBE",
    "#D2EDC8",
  ],
  TAG_COLOURS: [
    "#025DF4",
    "#DB6BCF",
    "#2498D1",
    "#BBBDE6",
    "#4045B2",
    "#21A97A",
    "#FF745A",
    "#007E99",
    "#FFA8A8",
    "#2391FF",
    "#FFC328",
    "#A0DC2C",
    "#946DFF",
    "#626681",
    "#EB4185",
    "#CD8150",
    "#36BCCB",
    "#327039",
    "#803488",
    "#83BC99",
  ],

  GROUP_COLOURS: [
    "#BBE7FE",
    "#D3B5E5",
    "#FFD4DB",
    "#EFF1DB",
    "#FBE7C6",
    "#B4F8C8",
    "#A0E7E5",
    "#FFAEBC",
    "#CDDAFD",
    "#FDE2E4",
  ],

  GROUP_NAME_BY_COLOURS: {
    "#BBE7FE": {
      name: "french_pass",
    },
    "#D3B5E5": {
      name: "thistle",
    },
    "#FFD4DB": {
      name: "pastel_pink",
    },
    "#EFF1DB": {
      name: "loafer",
    },
    "#FBE7C6": {
      name: "tequila",
    },
    "#B4F8C8": {
      name: "pale_turquoise",
    },
    "#A0E7E5": {
      name: "water_leaf",
    },
    "#FFAEBC": {
      name: "soft_pink",
    },
    "#CDDAFD": {
      name: "tropical_blue",
    },
    "#FDE2E4": {
      name: "misty_rose",
    },
    "#A2DBFA": {
      name: "default",
    },
  },

  DEFAULT_GROUP_NAME: "default#A2DBFA",
  TAG_MIN_LENGTH: 3,
  TAG_MAX_LENGTH: 20,
  MAX_ALLOWED_TAGS_IN_HIGHLIGHT: 5,

  MEDIA_SUPPORTED_LANGUAGE: [
    {
      language_code: "en-US",
      language: "English - North America",
    },
    {
      language_code: "en-IN",
      language: "English - India",
    },
    {
      language_code: "en-SG",
      language: "English - South East Asia",
    },
    {
      language_code: "en-GB",
      language: "English - Global",
    },
    {
      language_code: "es-ES",
      language: "Spanish",
    },
    {
      language_code: "zh",
      language: "Simplified Chinese",
    },
    {
      language_code: "fr-FR",
      language: "French",
    },
    {
      language_code: "de-DE",
      language: "German",
    },
    {
      language_code: "it-IT",
      language: "Italian",
    },
    {
      language_code: "ja-JP",
      language: "Japanese",
    },
    {
      language_code: "pt-BR",
      language: "Portuguese",
    },
    {
      language_code: "ru-RU",
      language: "Russian",
    },
  ],
  ENV_CHECK: [
    "dev",
    "qa",
    "staging",
    "www",
    "converz",
    "decode",
    "getdecode",
    "prod",
    "prestage",
    "localhost",
    "localhost:3000",
    "help",
  ],
  MAX_PARTICIPANTS: 15,
  domain: "getdecode.io",
  emojis: [
    {
      id: 1,
      emoji: "👍",
      title: "Like",
    },
    {
      id: 2,
      emoji: "👎",
      title: "Dislike",
    },
    {
      id: 3,
      emoji: "❤️",
      title: "Heart",
    },
    {
      id: 4,
      emoji: "😄",
      title: "Smile",
    },
    {
      id: 5,
      emoji: "😕",
      title: "Sad",
    },
  ],
  duration: [
    { id: 1, time: 15 },
    { id: 2, time: 30 },
    { id: 3, time: 45 },
    { id: 5, time: 60 },
    { id: 6, time: 75 },
    { id: 7, time: 90 },
  ],

  roles: [
    { id: 1, role: "Admin" },
    { id: 2, role: "Manager" },
    { id: 3, role: "Auditor" },
    { id: 4, role: "Viewer" },
  ],

  insights_colors: [
    "#64DAA1",
    "#1D438A",
    "#BD0630",
    "#6F96A4",
    "#EA5A4A",
    "#F28CAF",
  ],
  Marketplace_id: {
    FTP: "01FR4T0GF5QM2PNA0VCF1AFJK2",
    Webex: "01FRQ8H41C4PAJD3YBFQBGGHWV",
    MSTeams: "01FRQ8H41C04N0EBB3K6V33TN3",
  },

  // demo video link
  VideoLink: {
    demo_video: "https://vimeo.com/711628172",
    feature_video: "https://www.youtube.com/embed/fwUrl4JBiZY?controls=0",
  },

  COLORS: {
    POSITIVE_EMOTION: "#61D1B2",
    NEGATIVE_EMOTION: "#FFA8A8",
    ATTENTION: "#36BCCB",
    ENGAGEMENT: "#DB6BCF",
    CONFIDENCE: "#946DFF",
    TEXT_ANALYSIS: [
      "#DB6BCF",
      "#FFC328",
      "#2391FF",
      "#4045B2",
      "#EB4185",
      "#007E99",
      "#CD8150",
      "#327039",
      "#025DF4",
      "#803488",
    ],
    POSITIVE_SENTIMENT: "#A0DC2C",
    NEGATIVE_SENTIMENT: "#FFA8A8",
    NEUTRAL_SENTIMENT: "#EAE569",
  },

  SMARTKARROT: {
    app_id: "632e2c40-ff22-4ed9-b2ff-2fa7b836509d",
    product_id: "96ed2f15-b4e0-49cb-9f3e-82671fdba743",
  },

  NOTIFICATION_MESSAGE: {
    mediaMessage:
      "mediaUploadSuccess,mediaUploadFail,decodeRecordingReady,newCollectionAdded,mediaAccessShared,mediaAccessRevoked,mediaDeleted,collectionDeleted,mediaAddedToCollection,mediaRemovedFromCollection,mediaTranscriptReady,mediaAnalyticsReady,collectionAnalyticsReady,newInsightsPublished,insightsUpdated,translationReady,analticsRegenerated,tagUserTranscript",
    otherMessage:
      "newUserJoined,newIntegrationSetup,profileUpdated,authorizationRequired",
    meetingMessage:
      "meetingTimeUpdate,newMeetingInvite,upcomingMeetingReminder,meetingUpdate,meetingCancelled",
  },

  menuTopListItems: [
    {
      id: 0,
      name: "Home",
      imageSrc: decode,
      imageSrcColor: decode,
      path: "/dashboard",
      id_name: "dashboard",
    },
    {
      id: 1,
      name: "Home",
      path: "/dashboard",
      id_name: "home",
    },
    {
      id: 2,
      name: "Meetings",
      path: "/meetings",
      id_name: "meeting",
    },
    {
      id: 3,
      name: "Uploads",
      path: "/upload-media",
      id_name: "upload",
      config_key: "media_upload",
    },
    {
      id: 4,
      name: "Media",
      path: "/media",
      id_name: "media",
    },
    {
      id: 5,
      name: "Collections",
      path: "/collections",
      id_name: "collection",
      config_key: "collection_module",
    },
    {
      id: 12,
      name: "Marketplace",
      path: "/marketplace",
      id_name: "marketplace",
      config_key: "marketplace_apps",
    },
  ],
  menuBottomListItems: [
    {
      id: 6,
      name: "Search",
      id_name: "search",
      config_key: "search",
    },
    {
      id: 7,
      name: "Notifications",
      id_name: "notification",
    },
    {
      id: 8,
      name: "Invite",
      path: "/settings",
      id_name: "invite",
      config_key: "invite_users",
    },
    {
      id: 9,
      name: "Help",
      path: "/help.getdecode.io",
      id_name: "help",
    },
    {
      id: 10,
      name: "Avatar",
      path: "/settings",
    },
  ],

  RECAPTCH_SITE_KEY: "6LftMLMeAAAAAKAuW4UwzqKxuzYqYbKGFj87-lst",
  MAX_MEDIA_LIMIT: 100,
  SUPPORT_EMAIL: "support@entropiktech.com",
  supportedVersions: {
    chrome: 89,
    edge: 89,
    firefox: 91,
    safari: 14,
  },
};

import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import styles from "./ProjectTable.module.css";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { async } from "@firebase/util";
import { FiCopy } from "react-icons/fi";

interface ProjectTableProps {
  projects: any;
  projectEvent(projectEvent: any): void;
}

const ProjectTable: FC<ProjectTableProps> = (props: any) => {
  const headers = [
    "Type",
    "Project Name",
    "Respondents",
    "Dates",
    "Study Link",
    "Status",
  ];
  let role = useAppSelector((state: any) => state.user.role);
  role = role ? role : localStorage.getItem("role");
  const toast = useToast();

  const [value, setValue] = React.useState("");
  const { hasCopied, onCopy } = useClipboard(value);

  useEffect(() => {
    value && onCopy();
  }, [value]);

  const request = async (id: any, flag: number) => {
    const project = doc(db, "projects", id);
    console.log(project);


    // Set the "capital" field of the city 'DC'
    try {
      await updateDoc(project, {
        status: flag,
      });
      toast({
        title: "Project Updated",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      props.projectEvent();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.ProjectTable}>
      <Box>
        <TableContainer className={styles["project-table"]}>
          <Table variant="simple">
            <Thead>
              <Tr>
                {headers.map((header: any) => {
                  return <Th>{header}</Th>;
                })}
              </Tr>
            </Thead>
            <Tbody>
              {props.projects.map((project: any) => {
                return (
                  <Tr>
                    <Td>{project.type == 1 ? "Survey" : "Immersions"}</Td>
                    <Td>{project.name}</Td>
                    <Td>{project.no_resp || 0}</Td>
                    <Td>
                      {project.start_date} to {project.end_date}
                    </Td>
                    <Td>
                      <Tooltip label={project.link}>
                        <Box display="inline-flex">
                          {project.link.length <= 10
                            ? project.link
                            : project.link.substring(0, 10) + ".."}
                          {project.link && (
                            <FiCopy
                              className={styles["icon"]}
                              onClick={() => {
                                setValue(project.link);
                              }}
                            ></FiCopy>
                          )}
                        </Box>
                      </Tooltip>
                    </Td>
                    <Td>
                      {role == "owner" && project.status == 0 ? (
                        <>
                          <Button
                            variant="action-btn"
                            onClick={() => request(project.fid, 1)}
                          >
                            Approve
                          </Button>{" "}
                          <Button
                            variant="action-no-bg-noMinWidth"
                            onClick={() => request(project.fid, 2)}
                          >
                            Reject
                          </Button>
                        </>
                      ) : project.status == 1 ? (
                        <Box color="green">Approved</Box>
                      ) : project.status == 2 ? (
                        <Box color="red">Rejected</Box>
                      ) : (
                        "Waiting for Approval"
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default ProjectTable;

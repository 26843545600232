import { createSlice } from "@reduxjs/toolkit";

interface ProjectInterface {
  project: any;
}

const initialState: ProjectInterface = {
  project: {
    id: "",
    name: "Untitled",
    type: 2,
    link: "",
    start_date: "",
    end_date: "",
    no_resp: "",
    city: [
      {
        code: "DL",
        name: "Delhi",
      },
    ],
    gender: [
      {
        id: 1,
        name: "Male",
      },
    ],
    age: [
      {
        id: 1,
        name: "0-17",
      },
    ],
    education: {
      id: 1,
      name: "No Formal Education",
    },
    occupation: {
      id: 1,
      name: "Chiropractor",
    },
    income: {
      id: 1,
      name: "$0",
    },
    maritalStauts: {
      id: 1,
      name: "Single",
    },
    country: {
      name: "India",
      code: "IN",
    },
    ethnicity: {
      id: 1,
      name: "White",
    },
    cigName: [
      {
        id: 1,
        name: "American Club",
      },
    ],
    cigCount: { id: 1, name: "1-2 cigarettes" },
    profession: { id: 1, name: "None of the above" },
    interest: "",
    discussionUrl: "",
    status: "0",
  },
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    updateProject: (state, action) => {
      const data = { ...state.project, ...action.payload };
      state.project = data;
    },
  },
});

export const { updateProject } = projectSlice.actions;

export default projectSlice.reducer;

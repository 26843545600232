import React, { useEffect, useState } from "react";
import "./App.css";
import { routes } from "./router";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Menu from "./components/Menu/Menu";
import { Box } from "@chakra-ui/react";
import { useAppSelector } from "./store/hooks";

function App() {
  const loggedIn = useAppSelector((state: any) => state.user.loggedIn);

  const [sessionLoggedIn, setSessionLoggedIn] = useState(
    localStorage.getItem("loggedIn")
      ? JSON.parse(localStorage.getItem("loggedIn") as string)
      : false
  );
  useEffect(() => {
    console.log(loggedIn);
    setSessionLoggedIn(
      localStorage.getItem("loggedIn")
        ? JSON.parse(localStorage.getItem("loggedIn") as string)
        : false
    );
  }, [loggedIn, sessionLoggedIn]);

  return (
    <div className="App">
      <Router>
        {(loggedIn || sessionLoggedIn) &&
          window.location.pathname !== "/login" && <Menu />}
        <Box
          className={
            window.location.pathname !== "/login" ? "root-container" : ""
          }
        >
          <Routes>
            {routes.map((route: any, i) => {
              const Element = route.routeProps.element;
              return (
                <>
                  <Route
                    key={i}
                    path={route.routeProps.path}
                    element={<Element />}
                  />
                </>
              );
            })}
          </Routes>
        </Box>
      </Router>
    </div>
  );
}

export default App;

import {
  Box,
  Center,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
} from "@chakra-ui/react";
import Select, { components } from "react-select";
import respList from "../../utility/dummy/respList.json";
import countryList from "../../utility/dummy/country.json";
import cityList from "../../utility/dummy/city.json";
import ageList from "../../utility/dummy/age.json";
import genderList from "../../utility/dummy/gender.json";
import occupationList from "../../utility/dummy/occupation.json";
import educationList from "../../utility/dummy/education.json";
import incomeList from "../../utility/dummy/income.json";
import ethinicityList from "../../utility/dummy/enthnicity.json";
import profession from "../../utility/dummy/profession.json";
import cigCountList from "../../utility/dummy/cig-count.json";
import cigList from "../../utility/dummy/cig-names.json";
import maritalStatusList from "../../utility/dummy/maritalStatus.json";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./ProjectForm.module.css";
import { connect, ConnectedProps } from "react-redux";
import { updateProject } from "../../reducers/projects";

interface ProjectFormProps extends PropsFromRedux {}

let initProfile = {
  id: "",
  name: "Untitled",
  no_resp: "",
  city: [{ code: "DL", name: "Delhi" }],
  gender: [genderList[0]],
  age: [ageList[0]],
  education: educationList[0],
  occupation: occupationList[0],
  income: incomeList[0],
  cigName: [cigList[0]],
  cigCount: cigCountList[0],
  maritalStauts: maritalStatusList[0],
  country: { name: "India", code: "IN" },
  ethnicity: ethinicityList[0],
  profession: profession[profession.length - 1],
  interest: "",
  discussionUrl: "",
};

const ProjectForm: FC<ProjectFormProps> = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onBlur",
    defaultValues: initProfile,
  });

  const [defaultValues, setDefaultValues] = useState({
    name: "Untitled",
    no_resp: "",
    country: { name: "India", code: "IN" },
    gender: [genderList[0]],
    city: [{ code: "DL", name: "Delhi" }],
    age: [ageList[0]],
    education: educationList[0],
    occupation: occupationList[0],
    income: incomeList[0],
    maritalStauts: maritalStatusList[0],
    ethnicity: ethinicityList[0],
    cigName: [cigList[0]],
    cigCount: cigCountList[0],
    profession: profession[profession.length - 1],
    interest: "",
    discussionUrl: "",
  });

  const formChange = () => {
    props.updateProject(getValues());
  };

  const countryChange = (val: any) => {
    props.updateProject(getValues());
    setValue("country", val);
    setDefaultValues({ ...defaultValues, country: val });
  };

  const cityChange = (selectedCities: any[]) => {
    const updatedValues = { ...getValues(), city: selectedCities };
    props.updateProject(updatedValues);
    setValue("city", selectedCities);
    setDefaultValues({ ...defaultValues, city: selectedCities });
  };

  const cigCountChange = (val: any) => {
    props.updateProject(getValues());
    setValue("cigCount", val);
    setDefaultValues({ ...defaultValues, cigCount: val });
  };

  const cigNameChange = (selectedCigNames: any) => {
    const updatedValues = { ...getValues(), cigName: selectedCigNames };
    props.updateProject(updatedValues);
    setValue("cigName", selectedCigNames);
    setDefaultValues({ ...defaultValues, cigName: selectedCigNames });
  };

  const genderChange = (selectedGender: any) => {
    const updatedValues = { ...getValues(), gender: selectedGender };
    props.updateProject(updatedValues);
    setValue("gender", selectedGender);
    setDefaultValues({ ...defaultValues, gender: selectedGender });
  };

  const ageChange = (selectedAge: any) => {
    const updatedValues = { ...getValues(), age: selectedAge };
    props.updateProject(updatedValues);
    setValue("age", selectedAge);
    setDefaultValues({ ...defaultValues, age: selectedAge });
  };

  const professionChange = (selectedProfession: any) => {
    const updatedValues = { ...getValues(), profession: selectedProfession };
    props.updateProject(updatedValues);
    setValue("profession", selectedProfession);
    setDefaultValues({ ...defaultValues, profession: selectedProfession });
  };

  return (
    <div className={styles.ProjectForm}>
      <Box className={styles["form-container"]}>
        <Center>
          <Box className={styles["title"]}>
            Enter your no. of Respondents and Demographic details
          </Box>
        </Center>
        <Box>
          <form onChange={() => formChange()}>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem>
                <Box>
                  <Box className={styles["userForm--input"]}>
                    <FormControl>
                      <FormLabel htmlFor="name">Project Name</FormLabel>
                      <Input
                        type="text"
                        placeholder="Fullname"
                        autoComplete="off"
                        variant="outline"
                        color="#00205B"
                        fontSize="16px"
                        fontWeight="medium"
                        bg="#F7F7F7"
                        {...register("name")}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </GridItem>
              <GridItem>
                <Box>
                  <Box className={styles["userForm--input"]}>
                    <FormControl>
                      <FormLabel htmlFor="no_resp">
                        No. of Respondents
                      </FormLabel>
                      <Input
                        type="number"
                        placeholder="No. of Respondents"
                        autoComplete="off"
                        variant="outline"
                        color="#00205B"
                        fontSize="16px"
                        fontWeight="medium"
                        bg="#F7F7F7"
                        {...register("no_resp")}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </GridItem>
              <GridItem>
                <Box className={styles["userForm--input"]}>
                  <FormControl>
                    <FormLabel htmlFor="country">Country</FormLabel>
                    <Select
                      {...register("country")}
                      hideSelectedOptions={false}
                      options={countryList}
                      value={countryList.filter(
                        (option: any) =>
                          option.code === defaultValues.country.code
                      )}
                      isDisabled={true}
                      onChange={countryChange}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.code}
                    />
                  </FormControl>
                </Box>
              </GridItem>
              <GridItem>
                <Box>
                  <FormControl>
                    <FormLabel htmlFor="city">State/City</FormLabel>
                    <Select
                      {...register("city")}
                      hideSelectedOptions={false}
                      options={cityList}
                      value={cityList.filter((option: any) =>
                        defaultValues.city?.some(
                          (city: any) => city.code === option.code
                        )
                      )}
                      onChange={(selectedOptions: any) => {
                        cityChange(selectedOptions);
                      }}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.code}
                      isMulti={true}
                    />
                  </FormControl>
                </Box>
              </GridItem>
              <GridItem>
                <Box>
                  <FormControl>
                    <FormLabel htmlFor="gender">Gender</FormLabel>
                    <Select
                      {...register("gender")}
                      hideSelectedOptions={false}
                      options={genderList}
                      value={genderList.filter((option: any) =>
                        defaultValues.gender?.some(
                          (gender: any) => gender.id === option.id
                        )
                      )}
                      onChange={(selectedOptions: any) => {
                        genderChange(selectedOptions);
                      }}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                      isMulti={true}
                    />
                  </FormControl>
                </Box>
              </GridItem>
              <GridItem>
                <Box>
                  <Box>
                    <FormControl>
                      <FormLabel htmlFor="age">Age</FormLabel>
                      <Select
                        {...register("age")}
                        hideSelectedOptions={false}
                        options={ageList}
                        value={ageList.filter((option: any) =>
                          defaultValues.age?.some(
                            (age: any) => age.id === option.id
                          )
                        )}
                        onChange={(selectedOptions: any) => {
                          ageChange(selectedOptions);
                        }}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                        isMulti={true}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </GridItem>
              <GridItem>
                <Box>
                  <FormControl>
                    <FormLabel htmlFor="gender">
                      Weekly consumption of cigarettes of the respondents
                    </FormLabel>
                    <Select
                      {...register("cigCount")}
                      hideSelectedOptions={false}
                      options={cigCountList}
                      value={cigCountList.filter(
                        (option: any) => option.id === defaultValues.cigCount.id
                      )}
                      onChange={cigCountChange}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                    />
                  </FormControl>
                </Box>
              </GridItem>
              <GridItem>
                <Box>
                  <FormControl>
                    <FormLabel htmlFor="gender">
                      Brands the respondents smoke
                    </FormLabel>
                    <Select
                      {...register("cigName")}
                      hideSelectedOptions={false}
                      options={cigList}
                      value={cigList.filter((option: any) =>
                        defaultValues.cigName?.some(
                          (cigName: any) => cigName.id === option.id
                        )
                      )}
                      onChange={(selectedOptions: any) => {
                        cigNameChange(selectedOptions);
                      }}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                      isMulti={true}
                    />
                  </FormControl>
                </Box>
              </GridItem>
              {props.project.type === 2 && (
                <GridItem>
                  <Box>
                    <Box>
                      <FormControl>
                        <FormLabel htmlFor="discussionUrl">
                          Discussion Guide URL
                        </FormLabel>
                        <Input
                          type="text"
                          placeholder="Please enter your Discussion Guide URL"
                          autoComplete="off"
                          variant="outline"
                          color="#00205B"
                          fontSize="16px"
                          fontWeight="medium"
                          bg="#F7F7F7"
                          {...register("discussionUrl")}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </GridItem>
              )}
            </Grid>
            <Box mt="24px" w="66%">
              <FormControl>
                <FormLabel htmlFor="profession">
                  Respondent or Respondents family member employed in the
                  organisations listed
                </FormLabel>
                <Select
                  {...register("profession")}
                  hideSelectedOptions={false}
                  options={profession}
                  value={profession.filter(
                    (option: any) => option.id === defaultValues.profession.id
                  )}
                  onChange={professionChange}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.id}
                  menuPlacement="auto"
                />
              </FormControl>
            </Box>
          </form>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    project: state.project.project,
  };
};

const mapDispatchToProp = (dispatch: any) => {
  return {
    updateProject: (payload: any) => {
      dispatch(updateProject(payload));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ProjectForm);

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import styles from "./ProjectDashboardPreview.module.css";
import React, { FC, useEffect, useState } from "react";

import { db, auth } from "../../firebase";
import {
  collection,
  addDoc,
  Timestamp,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

interface ProjectDashboardPreviewProps {
  projectId: any;
}

const ProjectDashboardPreview: FC<ProjectDashboardPreviewProps> = (
  props: any
) => {
  const [loaded, setLoaded] = useState(false);
  const [projectData, setProjectData] = useState({} as any);
  const { hasCopied, onCopy } = useClipboard(projectData.link);

  useEffect(() => {
    fetchProject();
  }, []);

  const fetchProject = async () => {
    const docRef = doc(db, "projects", props.projectId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setProjectData(docSnap.data());
      setLoaded(true);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  return (
    <div className={styles.ProjectDashboardPreview}>
      {loaded && (
        <Box>
          <Box className={styles["content-box"]}>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem>
                <Box className={styles["title"]}>Project Name</Box>
                <Box className={styles["content"]}>{projectData.name}</Box>
              </GridItem>
              <GridItem>
                <Box className={styles["title"]}>Research Type</Box>
                <Box className={styles["content"]}>
                  {projectData.type == 1 ? "Survey" : "Immersions"}
                </Box>
              </GridItem>
              <GridItem>
                <Box className={styles["title"]}>Respondents</Box>
                <Box className={styles["content"]}>
                  {projectData.no_resp || 0}
                </Box>
              </GridItem>
              <GridItem>
                <Box className={styles["title"]}>Date From</Box>
                <Box className={styles["content"]}>
                  {projectData.start_date}
                </Box>
              </GridItem>
              <GridItem>
                <Box className={styles["title"]}>Date To</Box>
                <Box className={styles["content"]}>{projectData.end_date}</Box>
              </GridItem>
              <GridItem>
                <Box className={styles["title"]}>Country</Box>
                <Box className={styles["content"]}>
                  {projectData.country.name}
                </Box>
              </GridItem>
              <GridItem>
                <Box className={styles["title"]}>State</Box>
                <Box className={styles["content"]}>
                  {projectData.city[0]?.name}
                  {projectData.city.length > 1 && (
                    <Box className={styles["extra"]}>
                      +{projectData.city.length - 1}
                    </Box>
                  )}
                </Box>
              </GridItem>
              <GridItem>
                <Box className={styles["title"]}>Gender</Box>
                <Box className={styles["content"]}>
                  {projectData.gender[0]?.name}
                  {projectData.gender.length > 1 && (
                    <Box className={styles["extra"]}>
                      +{projectData.gender.length - 1}
                    </Box>
                  )}
                </Box>
              </GridItem>
              <GridItem>
                <Box className={styles["title"]}>Age</Box>
                <Box className={styles["content"]}>
                  {projectData.age[0]?.name}
                  {projectData.age.length > 1 && (
                    <Box className={styles["extra"]}>
                      +{projectData.age.length - 1}
                    </Box>
                  )}
                </Box>
              </GridItem>
              <GridItem>
                <Box className={styles["title"]}>
                  Weekly consumption of cigarettes of the respondents
                </Box>
                <Box className={styles["content"]}>
                  {projectData?.cigCount?.name || ""}
                </Box>
              </GridItem>
              <GridItem>
                <Box className={styles["title"]}>
                  Brands the respondents smoke
                </Box>
                <Box className={styles["content"]}>
                  {projectData?.cigName[0]?.name || ""}
                  {projectData?.cigName.length > 1 && (
                    <Box className={styles["extra"]}>
                      +{projectData.cigName.length - 1}
                    </Box>
                  )}
                </Box>
              </GridItem>
              {projectData.type === 2 && (
                <GridItem>
                  <Box className={styles["title"]}>Discussion Guide URL</Box>
                  <Tooltip label={projectData.discussionUrl}>
                    <Box className={styles["content"]}>
                      {projectData.discussionUrl &&
                      projectData.discussionUrl.length <= 30
                        ? projectData.discussionUrl
                        : projectData?.discussionUrl?.substring(0, 30) + ".." ||
                          ""}
                    </Box>
                  </Tooltip>
                </GridItem>
              )}
            </Grid>
            <Box mt="24px">
              <Box className={styles["title"]}>
                Respondent or Respondents family member employed in the
                organisations listed
              </Box>
              <Tooltip label={projectData?.profession?.name || ""}>
                <Box className={styles["content"]}>
                  {projectData?.profession?.name || ""}
                </Box>
              </Tooltip>
            </Box>
            <Box className={styles["link-container"]}>
              <strong>Study link: </strong>
              {projectData.link}{" "}
              {projectData.link.length > 0 && (
                <Button variant="action-no-bg" onClick={onCopy} ml={2}>
                  {hasCopied ? "Copied" : "Copy"}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {!loaded && (
        <Alert status="warning">
          <AlertIcon />
          Loading data
        </Alert>
      )}
    </div>
  );
};

export default ProjectDashboardPreview;

import { createSlice } from "@reduxjs/toolkit";

interface User {
  name: string;
  email: string;
  role: string;
  loggedIn: boolean;
}

const initialState: User = {
  name: "",
  email: "",
  role: "",
  loggedIn: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.loggedIn = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});

export const { loginUser, setRole, setEmail } = userSlice.actions;

export default userSlice.reducer;

import { Box, Center, Input } from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import styles from "./SurveyForm.module.css";
import { connect, ConnectedProps } from "react-redux";
import { updateProject } from "../../reducers/projects";

interface SurveyFormProps extends PropsFromRedux {}

const SurveyForm: FC<SurveyFormProps> = (props: any) => {
  const [value, setValue] = useState("");
  const handleChange = (event: any) => {
    props.updateProject({ link: event.target.value });
    setValue(event.target.value);
  };
  return (
    <div className={styles.SurveyForm}>
      <Box className={styles["form-container"]}>
        <Center>
          <Box className={styles["title"]}>Study Link</Box>
        </Center>
        <Center mt="20vh">
          <Box className={styles["input-box"]}>
            <Input
              type="text"
              placeholder="Paste the study link here."
              autoComplete="off"
              variant="outline"
              color="#00205B"
              fontSize="16px"
              fontWeight="medium"
              bg="#F7F7F7"
              // disabled={true}
              onChange={handleChange}
              value={value}
            />
          </Box>
        </Center>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    project: state.project.project,
  };
};

const mapDispatchToProp = (dispatch: any) => {
  return {
    updateProject: (payload: any) => {
      dispatch(updateProject(payload));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(SurveyForm);

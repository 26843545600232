import { FC } from "react";

const LoggedIn: FC = (props: any) => {
  const isLoggedIn = localStorage.getItem("loggedIn")
    ? JSON.parse(localStorage.getItem("loggedIn") as string)
    : false;
  if (!isLoggedIn) {
    window.location.href = "/login";
  }
  return null;
};

export default LoggedIn;

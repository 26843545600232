import { Box, Button, Grid, GridItem, HStack, Spacer } from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import projectData from "../../utility/dummy/projects.json";
import ProjectCard from "../../components/Card/ProjectCard/ProjectCard";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import GetTitle from "../../components/GetTitle";
import LoggedIn from "../../components/LoggedIn/LoggedIn";

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = () => {
  const [projects, setProjects] = useState([] as any);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    const arr: any = [];
    const querySnapshot = await getDocs(collection(db, "projects"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const data = doc.data();
      data["fid"] = doc.id;
      arr.push(data);
    });
    arr.sort((a: any, b: any) => {
      const [dayA, monthA, yearA] = a.start_date.split("/");
      const [dayB, monthB, yearB] = b.start_date.split("/");
      const dateA: any = new Date(`${yearA}-${monthA}-${dayA}`);
      const dateB: any = new Date(`${yearB}-${monthB}-${dayB}`);
      return dateB - dateA;
    });
    console.log(arr);
    setProjects(arr);
  };

  console.log(projects);

  const navigate = useNavigate();
  const navigateTo = () => {
    navigate("/create-project");
  };
  return (
    <div className={styles.Dashboard}>
      <GetTitle message="Decode CX: Dashboard" />
      <LoggedIn />
      <Box>
        <Box className={styles["header"]}>
          <HStack>
            <Box className={styles["header-title"]}>Projects</Box>
            <Spacer />
            <Button variant="action-btn-primary" onClick={navigateTo}>
              New Project
            </Button>
          </HStack>
        </Box>
        <Box className={styles["content"]}>
          <Grid templateColumns="repeat(4, 1fr)" gap={6}>
            {projects.map((project: any) => {
              return (
                <GridItem w="100%">
                  <ProjectCard project={project}></ProjectCard>
                </GridItem>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Dashboard;

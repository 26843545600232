import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC, useState } from "react";
import ProjectDashboardPreview from "../../ProjectDashboardPreview/ProjectDashboardPreview";
import styles from "./ProjectCard.module.css";
import { AiFillEye } from "react-icons/ai";

interface ProjectCardProps {
  project: any;
}

const ProjectCard: FC<ProjectCardProps> = (props: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className={styles.ProjectCard}>
      <Box className={styles["card-box"]}>
        <Box className={styles["card-title"]}>
          <Box onClick={onOpen}>{props.project.name}</Box>
          {props.project.link.length > 0 && (
            <AiFillEye
              className={styles["icon"]}
              onClick={() => {
                window.open(
                  props.project.link + (props.project.type == 1 ? "/pr" : ""),
                  "_blank"
                );
              }}
            ></AiFillEye>
          )}
        </Box>
        <Box className={styles["card-desc"]} onClick={onOpen}>
          <Box>
            Type:
            {props.project.type == 1 ? " Survey" : " Immersions"}
          </Box>
          <Box>
            Status:
            {props.project.status == 0
              ? " Waiting for Approval"
              : props.project.status == 1
              ? " Approved"
              : " Rejected"}
          </Box>
        </Box>
      </Box>
      {
        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <ProjectDashboardPreview projectId={props.project.fid} />
            </ModalBody>
          </ModalContent>
        </Modal>
      }
    </div>
  );
};

export default ProjectCard;

import CreateProject from "./pages/CreateProject/CreateProject";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import ProjectStatus from "./pages/ProjectStatus/ProjectStatus";

export const routes = [
  {
    routeProps: {
      path: "/login",
      exact: true,
      element: Login,
    },
    name: "Login",
  },
  {
    routeProps: {
      path: "/dashboard",
      exact: true,
      element: Dashboard,
    },
    name: "Dashboard",
  },
  {
    routeProps: {
      path: "/create-project",
      exact: true,
      element: CreateProject,
    },
    name: "CreateProject",
  },
  {
    routeProps: {
      path: "/projects",
      exact: true,
      element: ProjectStatus,
    },
    name: "ProjectStatus",
  },
];

import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import styles from "./ProjectFormPreview.module.css";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { formJson } from "../../utility/dummy/formPreview";
import { useNavigate } from "react-router-dom";
import { updateProject } from "../../reducers/projects";
import { connect, ConnectedProps } from "react-redux";

interface ProjectFormPreviewProps extends PropsFromRedux {}

const ProjectFormPreview: FC<ProjectFormPreviewProps> = (props: any) => {
  const options: any = { day: "2-digit", month: "2-digit", year: "numeric" };
  const dummyData = new Date().toLocaleDateString("en-GB", options);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const { hasCopied, onCopy } = useClipboard(props.project.link);

  useEffect(() => {
    props.updateProject({ start_date: dummyData });
  }, []);

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    const startTime = start.toLocaleDateString("en-GB", options);
    const endTime = end ? end.toLocaleDateString("en-GB", options) : "";
    props.updateProject({ start_date: startTime });
    props.updateProject({ end_date: endTime });
    setEndDate(end);
  };
  return (
    <div className={styles.ProjectFormPreview}>
      <Box className={styles["form-container"]}>
        <Box className={styles["link-container"]}>
          <strong>Study link: </strong>
          {props.project.link}{" "}
          {props.project.link.length > 0 && (
            <Button variant="action-no-bg" onClick={onCopy} ml={2}>
              {hasCopied ? "Copied" : "Copy"}
            </Button>
          )}
        </Box>
        <HStack alignItems="flex-start">
          <Box w="30%">
            <Box className={styles["box-header"]}>Tentative Timeline</Box>
            <Box className={styles["content-box"]} h="386px">
              <ReactDatePicker
                startDate={startDate}
                endDate={endDate}
                inline
                selectsRange
                onChange={onChange}
              />
            </Box>
          </Box>
          <Box w="70%">
            <Box className={styles["box-header"]}>Summary</Box>
            <Box className={styles["content-box"]}>
              <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <GridItem>
                  <Box className={styles["title"]}>Project Name</Box>
                  <Box className={styles["content"]}>{props.project.name}</Box>
                </GridItem>
                <GridItem>
                  <Box className={styles["title"]}>Research Type</Box>
                  <Box className={styles["content"]}>
                    {props.project.type == 1 ? "Survey" : "Immersions"}
                  </Box>
                </GridItem>
                <GridItem>
                  <Box className={styles["title"]}>Respondents</Box>
                  <Box className={styles["content"]}>
                    {props.project.no_resp || 0}
                  </Box>
                </GridItem>
                <GridItem>
                  <Box className={styles["title"]}>Date From</Box>
                  <Box className={styles["content"]}>
                    {props.project.start_date}
                  </Box>
                </GridItem>
                <GridItem>
                  <Box className={styles["title"]}>Date To</Box>
                  <Box className={styles["content"]}>
                    {props.project.end_date}
                  </Box>
                </GridItem>
                <GridItem>
                  <Box className={styles["title"]}>Country</Box>
                  <Box className={styles["content"]}>
                    {props.project.country.name}
                  </Box>
                </GridItem>
                <GridItem>
                  <Box className={styles["title"]}>State</Box>
                  <Box className={styles["content"]}>
                    {props.project.city[0]?.name}
                    {props.project.city.length > 1 && (
                      <Box className={styles["extra"]}>
                        +{props.project.city.length - 1}
                      </Box>
                    )}
                  </Box>
                </GridItem>
                <GridItem>
                  <Box className={styles["title"]}>Gender</Box>
                  <Box className={styles["content"]}>
                    {props.project.gender[0]?.name}
                    {props.project.gender.length > 1 && (
                      <Box className={styles["extra"]}>
                        +{props.project.gender.length - 1}
                      </Box>
                    )}
                  </Box>
                </GridItem>
                <GridItem>
                  <Box className={styles["title"]}>Age</Box>
                  <Box className={styles["content"]}>
                    {props.project.age[0]?.name}
                    {props.project.age.length > 1 && (
                      <Box className={styles["extra"]}>
                        +{props.project.age.length - 1}
                      </Box>
                    )}
                  </Box>
                </GridItem>
                <GridItem>
                  <Box className={styles["title"]}>
                    Weekly consumption of cigarettes of the respondents
                  </Box>
                  <Box className={styles["content"]}>
                    {props.project?.cigCount?.name || ""}
                  </Box>
                </GridItem>
                <GridItem>
                  <Box className={styles["title"]}>
                    Brands the respondents smoke
                  </Box>
                  <Box className={styles["content"]}>
                    {props.project?.cigName[0]?.name || ""}
                    {props.project?.cigName.length > 1 && (
                      <Box className={styles["extra"]}>
                        +{props.project.cigName.length - 1}
                      </Box>
                    )}
                  </Box>
                </GridItem>
                {props.project.type === 2 && (
                  <GridItem>
                    <Box className={styles["title"]}>Discussion Guide URL</Box>
                    <Tooltip label={props.project?.discussionUrl || ""}>
                      <Box className={styles["content"]}>
                        {props.project.discussionUrl.length <= 30
                          ? props.project.discussionUrl
                          : props.project.discussionUrl.substring(0, 30) + ".."}
                      </Box>
                    </Tooltip>
                  </GridItem>
                )}
              </Grid>
              <Box mt="24px">
                <Box className={styles["title"]}>
                  Respondent or Respondents family member employed in the
                  organisations listed
                </Box>
                <Tooltip label={props.project.profession.name || ""}>
                  <Box className={styles["content"]}>
                    {props.project.profession.name}
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </HStack>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    project: state.project.project,
  };
};

const mapDispatchToProp = (dispatch: any) => {
  return {
    updateProject: (payload: any) => {
      dispatch(updateProject(payload));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ProjectFormPreview);

import React, { FC } from "react";
import {
  FormErrorMessage,
  FormLabel,
  Input,
  FormControl,
  InputGroup,
  Box,
} from "@chakra-ui/react";
import { RegisterOptions } from "react-hook-form";

interface TextInputImpl {
  label: string;
  name: any;
  placeholder?: string;
  type: string;
  registerOptions?: RegisterOptions;
  variant?: string;
  handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => null;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => null;
  labelClassName?: string;
  customErrorMsg?: string;
  RightIconElement?: React.ReactNode;
  register?: any;
  error?: any;
  isDecodeApp?: any;
  className?: string;
}

const TextInput: FC<TextInputImpl> = ({
  label,
  name,
  placeholder = "",
  handleBlur,
  type = "",
  handleChange,
  variant = "outline",
  RightIconElement,
  labelClassName,
  customErrorMsg,
  registerOptions,
  register,
  error,
  isDecodeApp,
  className,
}) => {
  const textInput = register(name, registerOptions);
  return (
    <FormControl isInvalid={error}>
      <FormLabel
        htmlFor={name}
        className={labelClassName}
        color={isDecodeApp ? "#ffffff" : "#414042"}
      >
        {label}
      </FormLabel>
      <InputGroup>
        <Input
          color="#00205B"
          fontSize="16px"
          fontWeight="medium"
          bg="#F7F7F7"
          type={type}
          autoComplete="off"
          placeholder={placeholder}
          variant={variant}
          name={textInput.name}
          {...textInput}
          onChange={(e) => {
            textInput.onChange(e);
            handleChange?.(e);
          }}
          onBlur={(e) => {
            textInput.onBlur(e);
            handleBlur?.(e);
          }}
          className={className || ""}
        />
        {RightIconElement}
      </InputGroup>
      <FormErrorMessage fontSize="xs">
        {registerOptions &&
          Object.entries(registerOptions).map(([key, record]) => {
            return error?.type === key && record.message;
          })}
      </FormErrorMessage>
      {customErrorMsg && <Box>{customErrorMsg}</Box>}
    </FormControl>
  );
};

export default TextInput;

import {
  Avatar,
  Box,
  Divider,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { FC } from "react";
import styles from "./Menu.module.css";
import decodeLogo from "../../assets/images/decode/decode-full.svg";
import { AiOutlineHome } from "react-icons/ai";
import { BsCollection } from "react-icons/bs";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../reducers/user";
import { connect, ConnectedProps } from "react-redux";

interface MenuProps extends PropsFromRedux {}

const Menu: FC<MenuProps> = (props: any) => {
  const navigate = useNavigate();

  const redirect = (path: string) => {
    navigate(path, { replace: true });
  };

  const menuArr = [
    {
      id: 1,
      name: "Dashboard",
      path: "/dashboard",
      icon: <AiOutlineHome className={styles["icon"]} />,
    },
    {
      id: 2,
      name: "Create Project",
      path: "/create-project",
      icon: <MdOutlineCreateNewFolder className={styles["icon"]} />,
    },
    {
      id: 2,
      name: "Projects",
      path: "/projects",
      icon: <BsCollection className={styles["icon"]} />,
    },
  ];

  const logOut = () => {
    props.login(false);
    localStorage.setItem("loggedIn", "false");
    window.location.href = "/login";
  };
  return (
    <div className={styles.Menu}>
      <Box>
        <Box className={styles["menu-box"]}>
          <VStack height="100%" p="20px 0px">
            <Box className={styles["image-box"]}>
              <Image
                className={styles["image"]}
                src={decodeLogo}
                onClick={() => redirect("/dashboard")}
              />
              <Divider />
              {menuArr.map((menu: any, i) => {
                return (
                  <Box
                    className={styles["icon-box"]}
                    key={i}
                    onClick={() => redirect(menu.path)}
                  >
                    <Tooltip label={menu.name} placement="right-start">
                      <Box>{menu.icon}</Box>
                    </Tooltip>
                  </Box>
                );
              })}
            </Box>
            <Spacer />
            <Box>
              <Popover placement="right-end">
                <PopoverTrigger>
                  <Avatar
                    cursor="pointer"
                    bg="teal.500"
                    name={props.email}
                    src="https://bit.ly/tioluwani-kolawole"
                  />
                </PopoverTrigger>
                <PopoverContent w="100px">
                  <PopoverBody>
                    <Box
                      className={styles["logout"]}
                      onClick={() => {
                        logOut();
                      }}
                    >
                      Logout
                    </Box>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </VStack>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loggedIn: state.loggedIn,
    email: state.email,
  };
};
const mapDispatchToProp = (dispatch: any) => {
  return {
    login: (flag: any) => {
      dispatch(loginUser(flag));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProp);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Menu);
